import * as React from "react"

const SvgComponent = (props) => (
  <svg
    viewBox="0 0 2250 2250"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <circle
      cx={1125}
      cy={1125}
      r={1125}
      style={{
        fill: "#43bccd",
      }}
    />
    <path d="M1564.92 815.148a51.25 51.25 0 0 1-15.011-36.239v-.008c0-21.918 13.203-41.678 33.453-50.065 20.249-8.388 43.557-3.752 59.056 11.747l345.679 345.679c21.394 21.394 21.394 56.082 0 77.476l-345.679 345.679c-15.499 15.499-38.807 20.135-59.056 11.747-20.25-8.387-33.453-28.147-33.453-50.065v-11.592a23.283 23.283 0 0 1 6.82-16.464l279.305-279.305a54.783 54.783 0 0 0 0-77.476L1564.92 815.148ZM685.077 1434.85a51.25 51.25 0 0 1 15.011 36.239v.008c0 21.918-13.203 41.678-33.453 50.065-20.249 8.388-43.557 3.752-59.056-11.747L261.9 1163.736c-21.394-21.394-21.394-56.082 0-77.476l345.679-345.679c15.499-15.499 38.807-20.135 59.056-11.747 20.25 8.387 33.453 28.147 33.453 50.065v11.592a23.283 23.283 0 0 1-6.82 16.464L413.963 1086.26a54.783 54.783 0 0 0 0 77.476l271.114 271.114ZM779.962 1679.56c-26.203-15.128-35.18-48.634-20.052-74.836 106.397-184.286 491.597-851.472 597.994-1035.76 15.128-26.203 48.634-35.181 74.836-20.053l37.298 21.534c26.203 15.128 35.18 48.634 20.052 74.836-106.397 184.286-491.597 851.472-597.994 1035.76-15.128 26.203-48.634 35.181-74.836 20.053l-37.298-21.534Z" />
  </svg>
)

export default SvgComponent